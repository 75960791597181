import classNames from 'classnames';
import GoogleReviewBadge from 'components/reviewBadge/googleReviewBadge';
import TrustpilotReviewBadge from 'components/reviewBadge/trustpilotReviewBadge';
import Image from 'next/image';
import React from 'react';
import { BlockComponentProps } from 'strapi/types/utils';
import { getResponsiveImgHeight } from 'util/getResponsiveImgSize';

import StrapiRichText from '../StrapiRichText';
import styles from './TextWithImageAndList.module.scss';

export const TextWithImageAndList = ({
  block,
  onError,
}: BlockComponentProps<'marketing-website.text-with-image-list'>) => {
  const {
    title,
    showTitleUnderline,
    reviewBadge,
    body,
    image,
    imagePosition,
    items,
  } = block;

  if (!image?.data) return onError('Missing image data');
  if (!image.data.attributes.alternativeText)
    return onError('Missing alt text for the image');
  if (!items || items.length === 0) return onError('Missing repeatable area');
  if (items.length > 3)
    return onError('Repeatable area can contain up to 3 items');
  const { width, height } = image.data.attributes;
  if (!width || !height)
    return onError(
      'Dimensions were missing on the image payload. Try replace with .png if image file is .avif.'
    );

  /* We calculate the image height based on the max width we know the image will have on the page, and pass the dimensions to Image:
    https://nextjs.org/docs/pages/building-your-application/optimizing/images#image-sizing */
  const RESIZED_IMAGE_WIDTH = 400;
  const responsiveHeight = getResponsiveImgHeight(RESIZED_IMAGE_WIDTH, {
    width,
    height,
  });

  const isBodyEmpty =
    body?.[0].children[0].type === 'text' &&
    body?.[0].children[0].text.length === 0;

  return (
    <section
      className={classNames(
        'p-body d-flex ai-center gap32 mt56',
        styles.container
      )}
    >
      <div
        className={classNames(styles.imageContainer, {
          [styles.right]: imagePosition === 'RIGHT',
          [styles.left]: imagePosition !== 'RIGHT',
        })}
      >
        <Image
          src={image.data.attributes.url}
          width={RESIZED_IMAGE_WIDTH}
          height={responsiveHeight}
          alt={image.data.attributes.alternativeText}
        />
      </div>
      <div
        className={classNames(styles.textContainer, {
          [styles.right]: imagePosition !== 'RIGHT',
          [styles.left]: imagePosition === 'RIGHT',
        })}
      >
        <h2 className="p-h2 p--serif">{title}</h2>
        {showTitleUnderline && (
          <div
            data-aos="animate-underline"
            data-aos-once="true"
            className={`br8 bg-purple-500 ${styles.separator}`}
          />
        )}
        {body && !isBodyEmpty && (
          <div className="mt16">
            <StrapiRichText richText={body} />
          </div>
        )}
        <div
          className={classNames('d-flex fd-column gap32', styles.itemContainer)}
        >
          {items.map((item) => {
            if (!item.section_icon?.data)
              return onError('Missing section icon');
            const { url: src, alternativeText: alt } =
              item.section_icon.data.attributes;

            if (!alt) return onError('Missing alt text');
            if (!item.section_description_richtext)
              return onError('Missing section_description_richtext');

            return (
              <div className="d-flex gap16" key={item.section_title}>
                <div>
                  <Image src={src} width={32} height={32} alt={alt} />
                </div>
                <div>
                  <h3 className="p-h4">{item.section_title}</h3>
                  <div className="wmx7 mt8">
                    <StrapiRichText
                      richText={item.section_description_richtext}
                      paragraphClassName="p-p mt8 tc-grey-600"
                      listClassName="tc-grey-600"
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {reviewBadge === 'GOOGLE' && (
          <div className="mt32">
            <GoogleReviewBadge />
          </div>
        )}
        {reviewBadge === 'TRUSTPILOT' && (
          <div className="mt32">
            <TrustpilotReviewBadge />
          </div>
        )}
      </div>
    </section>
  );
};
